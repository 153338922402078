function Model() {
	return {
		controllerName: 'Dashboard',
		model: {},
		enum: {
			serviceRunning: 1,
			jobRunning: 1,
			jobFailed: 3
		},
		serviceEnum: {
			HMail: 1,
			Reminder: 2,
			Email: 3,
		}
	};
}

export { Model };
