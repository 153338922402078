var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-card col-xs-12 col-md-6 col-lg-3" },
    [
      _c("div", { staticClass: "p-3 card shadow" }, [
        _c("div", { staticClass: "d-flex flex-wrap flex-md-nowrap" }, [
          _c(
            "div",
            {
              staticClass: "card-badge rounded shadow mb-2 p-4",
              class: _vm.design.color,
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: _vm.design.icon, size: "2x" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex ml-auto flex-column text-right" }, [
            _c("span", { staticClass: "h5 font-weight-normal mb-0" }, [
              _vm._v(_vm._s(this.item.Title)),
            ]),
            _c(
              "div",
              { staticClass: "d-flex align-self-end", class: _vm.stateClass },
              [
                _c("h2", { staticClass: "mb-0" }, [
                  _vm._v(_vm._s(this.item.StateText)),
                ]),
              ]
            ),
          ]),
        ]),
        _c("hr", {
          attrs: { role: "separator", "aria-orientation": "horizontal" },
        }),
        _c(
          "div",
          { staticClass: "d-flex flex-column px-2 overflow-auto" },
          [
            _c(
              "div",
              {
                staticClass: "d-flex flex-row align-items-center",
                class: _vm.msgClass,
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2 fa-lg text-info",
                  attrs: { icon: "info-circle" },
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$i18n.t("lastcheck")) +
                      " " +
                      _vm._s(_vm._f("dateTime")(this.item.Checked))
                  ),
                ]),
              ],
              1
            ),
            _vm._l(this.item.Errors, function (msg) {
              return _c(
                "div",
                {
                  key: msg.index,
                  staticClass: "d-flex flex-row align-items-center py-1",
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2 fa-lg text-danger",
                    attrs: { icon: "skull-crossbones" },
                  }),
                  _c("span", [_vm._v(_vm._s(msg))]),
                ],
                1
              )
            }),
            _vm._l(this.item.Warnings, function (msg) {
              return _c(
                "div",
                {
                  key: msg.index,
                  staticClass: "d-flex flex-row align-items-center py-1",
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2 fa-lg text-warning",
                    attrs: { icon: "exclamation-triangle" },
                  }),
                  _c("span", [_vm._v(_vm._s(msg))]),
                ],
                1
              )
            }),
            _vm._l(this.item.Infos, function (msg) {
              return _c(
                "div",
                {
                  key: msg.index,
                  staticClass: "d-flex flex-row align-items-center py-1",
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2 fa-lg text-info",
                    attrs: { icon: "info-circle" },
                  }),
                  _c("span", [_vm._v(_vm._s(msg))]),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }