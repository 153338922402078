<template>
	<b-card class="bcard-wrapper">
		<b-card-title>
			<link-to-manual tooltip="pmanualdashboar3296"></link-to-manual>
		</b-card-title>

		<dashboard-detail></dashboard-detail>
	</b-card>
</template>

<script>
import vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import dashboardDetail from './dashboard.detail';

vue.component('font-awesome-icon', FontAwesomeIcon);
vue.component('dashboard-detail', dashboardDetail);

export default {
	components: {},
	data() {
		return {};
	},
	methods: {},
	computed: {},
};
</script>
