<template>
	<div v-if="this.isLoaded" class="d-flex flex-wrap">
		<item-card
			v-for="item in this.model"
			:key="item.index"
			:item=item
		>
		</item-card>
	</div>
</template>
<script>
import vue from 'vue';

import loading from '../../mixins/loading.mixin';

import { Model } from './dashboard.model';
import service from './dashboard.service';
import itemCard from './dashboard-item-card.component';
import { loadDashboardState } from '../../store/actions';

vue.component('item-card', itemCard);

export default {
	mixins: [loading],
	data() {
		return Model(this.$i18n);
	},
	computed: {},
	async created() {
		this.startLoading();
		await this.fetchData();
		this.stopLoading();
	},

	methods: {
		async fetchData() {
			let resp = await service.getList();
			this.model = resp.data;
			this.$store.dispatch(loadDashboardState);
		},
	},
};
</script>

<style lang="scss" scoped></style>
