<template>
	<div class="service-card col-xs-12 col-md-6 col-lg-3">
		<div class="p-3 card shadow">
			<div class="d-flex flex-wrap flex-md-nowrap">
				<div class="card-badge rounded shadow mb-2 p-4" :class="design.color">
					<font-awesome-icon :icon="design.icon" size="2x"></font-awesome-icon>
				</div>
				<div class="d-flex ml-auto flex-column text-right">
					<span class="h5 font-weight-normal mb-0">{{ this.item.Title }}</span>
					<div class="d-flex align-self-end" :class="stateClass">
						<h2 class="mb-0">{{ this.item.StateText }}</h2>
					</div>
				</div>
			</div>
			<hr role="separator" aria-orientation="horizontal" class="" />
			<div class="d-flex flex-column px-2 overflow-auto">
				<div class="d-flex flex-row align-items-center" :class="msgClass">
					<font-awesome-icon class="mr-2 fa-lg text-info" icon="info-circle"></font-awesome-icon>
					<span>{{ $i18n.t('lastcheck') }} {{ this.item.Checked | dateTime }}</span>
				</div>
				<div
					v-for="msg in this.item.Errors"
					:key="msg.index"
					class="d-flex flex-row align-items-center py-1"
				>
					<font-awesome-icon class="mr-2 fa-lg text-danger" icon="skull-crossbones"></font-awesome-icon>
					<span>{{ msg }}</span>
				</div>
				<div
					v-for="msg in this.item.Warnings"
					:key="msg.index"
					class="d-flex flex-row align-items-center py-1"
				>
					<font-awesome-icon class="mr-2 fa-lg text-warning" icon="exclamation-triangle"></font-awesome-icon>
					<span>{{ msg }}</span>
				</div>
				<div
					v-for="msg in this.item.Infos"
					:key="msg.index"
					class="d-flex flex-row align-items-center py-1"
				>
					<font-awesome-icon class="mr-2 fa-lg text-info" icon="info-circle"></font-awesome-icon>
					<span>{{ msg }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			GlobalStateEnum:
			{
				Ok: 1,
				Warning: 2,
				Error: 3
			},
			DashboardItemEnum:
			{
				// jobs
				Processes: 1,
				// portal services
				hMailServer: 2,
				EmailService: 3,
				ReminderApp: 4
			}
		};
	},
	props: {
		item: { type: Object }
	},
	computed: {
		stateClass() {
			switch (this.item.State)
			{
				case this.GlobalStateEnum.Ok: return 'text-success';
				case this.GlobalStateEnum.Warning: return 'text-warning';
				default: return 'text-danger';
			}
		},
		msgClass() {
			return this.anyMessages === true ? 'py-1' : '';
		},
		design()
		{
			switch (this.item.Id)
			{
				case this.DashboardItemEnum.Processes: return { icon: 'cogs', color: 'bg-secondary' };
				case this.DashboardItemEnum.hMailServer: return { icon: 'mail-bulk', color: 'bg-primary' };
				case this.DashboardItemEnum.ReminderApp: return { icon: 'cog', color: 'bg-warning' };
				case this.DashboardItemEnum.EmailService: return { icon: 'envelope', color: 'bg-mu-orange' };
				default: return { icon: 'cog', color: 'bg-danger-gray' };
			}
		},
		anyMessages()
		{
			return (this.item.Errors != null && this.item.Errors.length > 0)
				   ||
				   (this.item.Warnigs != null && this.item.Warnigs.length > 0)
				   ||
				   (this.item.Infos != null && this.item.Infos.length > 0);

		}
	},
};
</script>
<style lang="scss" scoped>
.service-card {
	.card {
		margin-top: 30px;
		margin-bottom: 15px;
		.card-badge {
			position: relative;
			max-height: 90px;
			width: auto;
			top: -30px;
			color: white;
			display: flex;
			align-items: center;
		}
		.overflow-auto {
			max-height: 155px;
		}
	}
}
</style>
